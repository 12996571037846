<!-- mainPage -->
<template>
  <div v-loading="loading" class="mainPage">
    <listTable
      show-site-name
      show-channel-name
      show-issue-time
      :table-data="tableData"
      :table-page-obj="tablePageObj"
      :table-column="tableColumn"
      :total-data-length="totalDataLength"
      asc-string="issueTime,ASC"
      desc-string="issueTime,DESC"
      :data-track-text="dataTrackText"
      @getTableData="getTableData"
      @handleOpenUrl="handleOpenUrl"
      @changeSort="changeSort"
      @changeChooseTime="changeChooseTime"
      @handleChangePageData="changePageData"
    ></listTable>
  </div>
</template>

<script>
import listTable from '@comp/listTable'
import { mapState } from 'vuex'
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
const api = ProjectConfig.informationAggregation
export default {
  name: 'AcademicInformation',
  data() {
    return {
      loading: true,
      tableData: [],
      tablePageObj: {
        page: 1,
        size: 10,
        sort: 'issueTime,DESC'
      },
      tableColumn: [
        {
          title: '标题',
          prop: 'title',
          special: true,
          width: '400px',
          notSortable: true
        },
        {
          title: '信息来源',
          prop: 'siteName',
          notSortable: true
        },
        {
          title: '频道',
          prop: 'channelName',
          notSortable: true
        },
        {
          title: '发布时间',
          prop: 'issueTime',
          notSortable: true
        }
      ],
      totalDataLength: 0
    }
  },
  props: {
    type: {
      // 1是会议资讯2是学术资讯
      type: Number,
      default: 1
    }
  },
  components: { listTable },
  computed: {
    ...mapState('incrementService', ['magId']),
    dataTrackText() {
      return (
        '增值服务-资讯聚合-' +
        (this.type == 1 ? '会议资讯' : this.type == 2 ? '学术资讯' : '')
      )
    }
  },
  mounted() {
    if (!this.magId) {
      this.loading = false
    }
  },
  methods: {
    getTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        type: this.type,
        page: page - 1,
        size,
        sort: this.tablePageObj.sort,
        day: this.chooseTime,
        reprintStatus: ''
      }
      let url = api.GetListData.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.loading = false
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.tablePageObj = {
            page,
            size,
            sort: this.tablePageObj.sort,
            total: totalElements
          }
          this.tableData = content
          this.tableData.map((item) => {
            item.abstract = item.informationAbstract
          })
          this.totalDataLength = totalElements
        } else {
          this.loading = false
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleOpenUrl(chooseItem) {
      const { url = '' } = chooseItem
      if (url && url !== '') {
        window.open(url)
      }
    },
    changeSort(value) {
      this.tablePageObj.sort = value
      this.getTableData()
    },
    changeChooseTime(chooseTime) {
      this.chooseTime = chooseTime
      this.getTableData()
    },
    changePageData(pageObj) {
      this.tablePageObj.page = pageObj.page
      this.getTableData(pageObj)
    }
  }
}
</script>

<style lang="scss" scoped></style>
